import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import { TermsAndConditions } from 'src/components/saas-memberships/terms-and-conditions/terms-and-conditions';
import SEO from 'src/components/seo';

const TermsAndConditionsPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Saas Membership Terms and Conditions" />
      <PrivateRoute>
        <TermsAndConditions />
      </PrivateRoute>
    </Layout>
  );
};

export default TermsAndConditionsPage;
