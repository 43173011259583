import React, { useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { meshGatewayClient } from 'src/apollo/client';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import Button from 'src/components/button/button';
import Panel from 'src/components/panel/panel';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import ROUTES from 'src/utils/routes';
import * as yup from 'yup';

import * as styles from './terms-and-conditions.module.less';

import 'react-toastify/dist/ReactToastify.css';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';
import Form from 'src/components/form-components/index';
import PageHeader from 'src/components/page-header/page-header';
import { ADD_ORG_EXTENSIONS } from 'src/components/saas-memberships/saas-memberships-queries';
import { convertInputToExtension } from 'src/components/saas-memberships/utils';
import { getOrganisationById_getOrganisationById } from 'src/graphql-types/saas/getOrganisationById';

const extensionNames = {
  termsAndConditions: 'TERMS_AND_CONDITIONS',
};

const fieldNames = {
  TERMS_AND_CONDITIONS: 'termsAndConditions',
};

interface FormData {
  termsAndConditions: string;
}

interface Props {
  existingOrganisation?: getOrganisationById_getOrganisationById;
  orgId?: string;
}

export const TermsAndConditionsForm = ({ existingOrganisation, orgId }: Props) => {
  const { t } = useTranslation();

  const [addTermsAndConditions, { loading }] = useMutation(ADD_ORG_EXTENSIONS, {
    client: meshGatewayClient,
    onError: () => {
      toast.error(t('error'));
    },
    onCompleted: () => {
      toast.success(t('settings updated'));
      navigate(ROUTES.SAAS_MEMBERSHIP_SETTINGS);
    },
  });

  const handleSubmit = async (addTermsAndConditionsInput: FormData) => {
    await addTermsAndConditions({
      variables: {
        clubSparkOrganisationId: orgId,
        updateInput: convertInputToExtension(addTermsAndConditionsInput, extensionNames),
      },
    });
  };

  const schema = yup
    .object()
    .shape({
      termsAndConditions: yup
        .string()
        .url(t('must be a valid URL', { type: 'terms and conditions' }))
        .required(t('is required', { type: 'terms and conditions' })),
    })
    .required() as yup.ObjectSchema<FormData>;

  const methods = useForm<FormData>({
    defaultValues: generateInitialFormValues(existingOrganisation),
    resolver: yupResolver(schema) as Resolver<FormData>,
    reValidateMode: 'onBlur',
    shouldUnregister: false,
  });

  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          {
            name: t('membership settings'),
            to: `${ROUTES.SAAS_MEMBERSHIP_SETTINGS}`,
            highlight: true,
          },
          {
            name: t('terms and conditions'),
            active: true,
          },
        ]}
      />
      <Grid className={styles.title} container justifyContent="space-between" alignItems="center">
        <PageHeader title={t('terms and conditions title')} />
      </Grid>
      <Panel>
        <Form methods={methods} onSubmit={handleSubmit}>
          <Form.TextField
            id={'tf-name'}
            label={t('upload terms and conditions')}
            name="termsAndConditions"
            placeholder={t('terms and conditions url placeholder')}
          />
          <Button loading={loading} spacing={{ margins: { auto: 'right', md: 'top' } }}>
            {t('save')}
          </Button>
        </Form>
      </Panel>
    </PageMaxWidth>
  );
};

export const generateInitialFormValues = (
  organisation: getOrganisationById_getOrganisationById | undefined,
): FormData => {
  const initialValues = {
    termsAndConditions: '',
  };

  if (!organisation?.extensions) return initialValues;

  for (const item of organisation.extensions) {
    const name = item.name;
    const value = item.value;

    switch (name) {
      case extensionNames.termsAndConditions.toString():
        initialValues[fieldNames[name]] = value;
        break;
      default:
        break;
    }
  }

  return initialValues;
};
